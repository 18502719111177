<template>
  <div @click="$emit('click', date)">
    <div class="time-box">
      <div>{{ date.begDate }}</div>
      <span></span>
      <div>{{ date.endDate }}</div>
    </div>
    <van-divider />
  </div>
</template>

<script>
import { Divider } from "vant";

export default {
  props: {
    date: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    [Divider.name]: Divider,
  },
};
</script>

<style scoped>
.time-box {
  width: 100%;
  height: 60px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  padding: 0 20px;
  color: #333333;
  align-items: center;
  justify-content: space-around;
}

.time-box span {
  width: 16px;
  height: 2px;
  background: #999999;
  border-radius: 1px;
}
</style>