<template>
  <!-- 医疗一次性趸交 -->
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container>
          <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="参加工作时间"></title-item>
                <year-month-input
                  class="date-input"
                  idx="0"
                  ref="begWorkDate"
                  v-model="begWorkDate"
                ></year-month-input>
              </div>
            </van-cell>
          </div>
          <van-divider />

          <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="预计退休时间"></title-item>
                <year-month-input
                  class="date-input"
                  idx="1"
                  ref="retireDate"
                  v-model="retireDate"
                ></year-month-input>
              </div>
            </van-cell>
          </div>
          <van-divider />

          <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="show = true">
                <title-item text="性别"></title-item>
                <span>{{ gender }}</span>
              </div>
            </van-cell>
          </div>
        </round-container>

        <round-add-container
          title="添加间断时间"
          :list="medicalGapRange"
          @add="$router.push({ name: 'MedicalDateRangePage' })"
        >
          <year-month-range
            v-for="(item, idx) in medicalGapRange"
            :key="idx"
            :date="item"
            @click.native="
              $router.push({
                name: 'MedicalDateRangePage',
                query: {
                  item: JSON.stringify(item),
                  isUpdate: true,
                },
              })
            "
          >
          </year-month-range>
        </round-add-container>
      </template>
      <template slot="footerBtn">
        <div class="btn-box btn-bk"
          @click="onSubmit"
        >
          开始计算
        </div>
      </template>
    </calc-page>

    <van-action-sheet
      title="选择性别"
      v-model="show"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import CalcPage from "@/components/CalcPage";
import { Cell, Divider, ActionSheet,Dialog } from "vant";
import { mapGetters, mapMutations } from "vuex";
import TitleItem from "@/views/calc/TitleItem";
import YearMonthInput from "@/components/YearMonthInput";
import YearMonthRange from "@/views/calc/components/YearMonthRange";
import RoundContainer from "@/views/calc/components/RoundContainer";
import RoundAddContainer from "@/views/calc/components/RoundAddContainer.vue";
import { calcMedicalPayInFull } from "@/api/tool";
import to from "@/utils/to";

export default {
  components: {
    TitleItem,
    // DataItem,
    YearMonthInput,
    YearMonthRange,
    RoundContainer,
    RoundAddContainer,
    [Cell.name]: Cell,
    [Divider.name]: Divider,
    [ActionSheet.name]: ActionSheet,
    CalcPage,
  },
  computed: {
    btnDisabled() {
      const { begWorkDate, retireDate } = this;
      if (
        begWorkDate === null ||
        retireDate === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    begWorkDate: {
      get() {
        return this.getMedicalPayInFull().begWorkDate;
      },
      set(val) {
        this.setMedicalPayInFull({ begWorkDate: val })
      }
    },
    retireDate: {
      get() {
        return this.getMedicalPayInFull().retireDate;
      },
      set(val) {
        this.setMedicalPayInFull({ retireDate: val })
      }
    },
    gender: {
      get() {
        return this.getMedicalPayInFull().gender;
      },
      set(val) {
        this.setMedicalPayInFull({ gender: val })
      }
    },
    medicalGapRange: {
      get() {
        return this.getMedicalPayInFull().medicalGapRange;
      },
      set(val) {
        this.setMedicalPayInFull({ medicalGapRange: val })
      }
    },
  },
  data() {
    return {
      minDate: new Date(),
      maxDate: new Date(),

      show: false,
      actions: [{ name: "男" }, { name: "女" }],
    };
  },
  mounted() {
    const curYear = new Date().getFullYear();

    this.minDate = new Date(curYear - 100, 0, 0); // 小于100岁
    this.maxDate = new Date(curYear - 18, 0, 0); // 大于18岁
  },
  methods: {
    onSelect(item) {
      this.gender = item.name;
    },
    async onSubmit() {
      if (this.btnDisabled) {
        this.$toast('请填写完整信息')
        return;
      }

      const itemBegWork = new Date(this.begWorkDate);
      const itemRetire = new Date(this.retireDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      if (itemBegWork > new Date()) {
         Dialog.alert({
          title: "参加工作时间不正确",
          message: "参加工作时间不能大于当前年月：" + currentYear + "/" + currentMonth + "，请重新输入"
        });
        return;
      }

      if (itemRetire < itemBegWork) {
         Dialog.alert({
          title: "预计退休日期不正确",
          message: "预计退休日期不能小于参加工作日期"
        });
        return;
      }


      let gapList = [];
      this.medicalGapRange.forEach((item) => {
        let ele = {};
        ele.begDate = item.begDate + "/01";
        ele.endDate = item.endDate + "/01";
        ele.level = item.level;

        gapList.push(ele);
      });

      let data = {
        gender: this.gender === "男" ? 1 : 0,
        begWorkDate: this.begWorkDate + "/01",
        retireDate: this.retireDate + "/01",
        gapList,
      };

      this.$showLoading("加载中");
      let [err, res] = await to(calcMedicalPayInFull(data));
      this.$hideLoading();

      if (err) {
        this.$showFail("计算失败");
        return
      }

      this.$router.push({
        name: "MedicalPayResultPage",
        query: { res: JSON.stringify(res) },
      });
    },
    ...mapGetters("calc", ["getMedicalPayInFull"]),
    ...mapMutations("calc", ["setMedicalPayInFull"]),
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box .unit {
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 1px;
}

.row-box >>> .van-cell::after {
  border: none;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}

.date-input {
  margin-right: 4px;
}
</style>