<template>
  <div class="round">
    <div class="top-btn" @click="$emit('add')">
      <img class="img-box" src="@/assets/imgs/calc/add.png" />{{ title }}
    </div>
    <div class="inner">
      <slot></slot>
      <div class="inner-empty" v-if="list.length <= 0">
        <img src="@/assets/imgs/calc/px-empty.png" />
        <div>点击上方按钮，添加记录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title:{
      type: String
    },
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.round {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 80px;
}

.top-btn {
  height: 50px;
  background: #dbf0ff;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #658CF1;
}

.img-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.inner {
  padding: 12px;
}

.inner-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
  margin: 45px 0;
}

.inner-empty img {
  width: 124px;
}
</style>